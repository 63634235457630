<template>
  <v-dialog v-model="show" max-width="1000" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
    <v-card>
      <v-card-title class="card-header-add font-weight-bold px-2 py-2 mb-5">
        <span class="font-size15">{{ $t('patient-presence-for') }} {{ patientName }}</span>
        <v-spacer />
        <v-icon color="white" @click="closeDialog">mdi-window-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="pa-1" cols="7">
            <template>
              <v-data-table
                  :items="patientsPresenceByPatientId[0]?.presenceData"
                  :headers="headers"
                  hide-default-footer
                  class="elevation-2 pa-2"
                  height="290"
                  max-height="290"
                  dense
                  fixed-header
                  :no-data-text="$t('no-data-available')"
                  :footer-props="footerProps"
                  :items-per-page="-1"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="font-size13 font-weight-medium text-left" style="height:15px !important;">
                      {{ medicalStaffName(item.presenceStatusDoneBy) }}
                    </td>
                    <td class="font-size13 font-weight-medium text-left" style="height:15px !important;">
                        {{ formatDateFromUTC(item.presenceDateChangeAt) }}
                    </td>
                    <td class="font-size13 font-weight-medium text-left px-0" style="height:15px !important;">
                      <v-icon color="primary">
                        {{ item.presenceStatus === true ? 'mdi mdi-arrow-right-bold' : 'mdi mdi-arrow-left-bold' }}
                      </v-icon>
                      {{ item.facilityName }}
                    </td>
                    <td class="font-size13 font-weight-medium text-center" style="height:15px !important;">
                      <v-icon @click="deleteField(item)" small color="primary" class="pointer">mdi-delete</v-icon>
                    </td>
                  </tr>
                </template>

              </v-data-table>
            </template>

          </v-col>
        <v-col cols="5" class="d-flex flex-column pa-2" >
          <v-row class="mb-1" dense>
            <v-row dense>
              <v-col cols="6">
                <v-text-field
                    v-model="patientPresenceStatusToSend"
                    readonly
                    outlined
                    dense
                    hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        outlined
                        dense
                        hide-details
                        v-model="date"
                        :label="$t('choose-date')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click="menu = !menu"
                    ></v-text-field>

                  </template>
                  <v-date-picker
                      v-model="date"
                      @input="menu = false"
                      :min="denySmallerDateForPatientPresence"
                      :key="datePickerKey"
                  ></v-date-picker>
                </v-menu>
              </v-col>
          </v-row>
          </v-row>
          <v-row dense v-if="patientPresenceStatus === false">
          <v-text-field
              class="my-1"
              v-model="facilityName"
              :label="$t('out-of-facility-name')"
              outlined
              dense
              hide-details
          ></v-text-field>
          </v-row>
          <v-row dense>
            <v-textarea
                class="my-1"
                v-model="presenceDescription"
                outlined
                :label="$t('out-of-facility-description')"
                hide-details
                no-resize
                :rows="patientPresenceStatus === false ? 7 : 9"
            ></v-textarea>
          </v-row>
        </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="ml-auto mr-1 mt-n5 white--text px-5"  color="primary2" @click="save">{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';

export default {
  name: 'PresenceDialog',
  data () {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      denySmallerDateForPatientPresence: '',
      datePickerKey: 0,
      presenceReasonChange: '',
      hospitalName: '',
      presenceDescription: '',
      selectedPresenceStatus: null,
      facilityName: '',
      presenceStatus: [{ id: 0, title: 'In' }, { id: 1, title: 'Out' }],
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
      headers: [
        { text: this.$t('name'), class: 'table-header height15-black pl-4', sortable: false, align: 'start' },
        { text: this.$t('date'), class: 'table-header height15-black pl-4', sortable: false, align: 'start', width: '25%' },
        { text: this.$t('facility'), class: 'table-header height15-black', sortable: false, align: 'start' },
        { text: this.$t('actions'), class: 'table-header height15-black', sortable: false, align: 'end', width: '5%' },
      ],
      patientPresenceStatusToSend: '',
      patientPresenceStatus: true,
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    patientId: {
      type: Number,
      required: true,
    },
    patientName: {
      type: String,
      required: true,
    },
    presence: {
      type: Array,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      pageHeight: 'pageHeight',
      hospitalId: 'authentication/getHospitalId',
      userSettings: 'authentication/getUserSettings',
    }),
    ...mapState({
      userData: (state) => state.authentication.userData,
      detailedUser: (state) => state.users.detailedUser,
      patientsPresenceByPatientId: (state) => state.appointments.patientsPresenceByPatientId,
      medicalStaff: (state) => state.hospitals.medicalStaff,
    }),
  },
  created () {
    this.getPatientPresence();
  },
  watch: {
    show: {
      handler (newVal) {
        if (newVal) {
          this.getPatientPresence();
        }
      },
      deep: true,
    },
  },

  methods: {
    getPatientPresence () {
      this.$store.dispatch('appointments/getPatientPresenceByPatientId', this.patientId).then(res => {
        const minDate = this.patientsPresenceByPatientId[0].presenceData[0]?.presenceDateChangeAt;
        this.denySmallerDateForPatientPresence = minDate;
        this.datePickerKey += 1;
        if (this.patientsPresenceByPatientId[0].presenceData.length > 0) {
          if (this.patientsPresenceByPatientId[0].presenceData[0]?.presenceStatus) {
          this.patientPresenceStatus = false;
          this.patientPresenceStatusToSend = this.$t('out');
        } else {
            this.patientPresenceStatus = true;
            this.patientPresenceStatusToSend = this.$t('in');
        }
      } else {
          this.patientPresenceStatus = false;
          this.patientPresenceStatusToSend = this.$t('out');
        }
      });
    },
    formatDateFromUTC (date) {
      return new Date(date).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    },
    deleteField (item) {
      this.$store.dispatch('appointments/deletePatientPresence', item.id)
          .then(res => {
            if (res.resFlag) {
              showSuccessAlert(this.$t('success-operation'));
            }
            const resString = String(res); // Convert res to a string
            if (resString.includes('Request failed with status code 403')) {
              showErrorAlert(this.$t('you-cant-delete-this-record-its-not-the-latest'));
              this.getPatientPresence();
            }
            this.getPatientPresence();
            this.$emit('reload-presence');
          });
    },
    medicalStaffName (staffId) {
      if (this.medicalStaff && this.medicalStaff.length > 0) {
        const matchedStaff = this.medicalStaff.find(staff => staff.id === staffId);
        if (matchedStaff) {
          return `${matchedStaff.firstName} ${matchedStaff.lastName}`;
        }
      }
      return '';
    },
    save () {
      const body = {
        patientId: this.patientId,
        presenceStatusDoneBy: this.userData.id,
        presenceReasonChange: this.presenceDescription,
        facilityId: this.patientPresenceStatus === true ? -1 : localStorage.getItem('selectedHospitalAssignedId'),
        facilityName: this.patientPresenceStatus === true ? this.userData.hospitalAssignedData.hospitalName : this.facilityName,
        additionalData: '',
        presenceStatus: this.patientPresenceStatus,
        presenceStatusDoneAt: new Date(),
        presenceDateChangeAt: this.date,
      };
      this.$store.dispatch('appointments/setPatientPresence', body)
          .then(() => {
            this.getPatientPresence();
            this.$emit('reload-presence');
            this.closeDialog();
          });
    },
    closeDialog () {
      this.selectedTemplate = null;
      this.medicalNote = '';
      this.facilityName = '';
      this.presenceDescription = '';
      this.$emit('update:show', false);
      this.$emit('reload-presence');
    },
  },
};
</script>
