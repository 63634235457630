// src/utils/billingUtils.js
export function aggregateData (billingsFromRepo) {
    const aggregatedData = {};

    for (const entry of billingsFromRepo) {
        if (entry.id === 0) { break; } // in this case exit from loop
        const hospitalId = entry.hospitalData.id;

        if (!(hospitalId in aggregatedData)) {
            aggregatedData[hospitalId] = {
                counter: 0,
                counter99453: 0,
                percent99453: 0.0,
                counter99454: 0,
                percent99454: 0.0,
                counter99457: 0,
                percent99457: 0.0,
                counter994581st: 0,
                percent994581st: 0.0,
                counter994582nd: 0,
                percent994582nd: 0.0,
            };
        }

        aggregatedData[hospitalId].counter += 1;

        if (entry.cptCode99453?.status === 1) {
            aggregatedData[hospitalId].counter99453 += 1;
        }
        if (entry.cptCode99454?.status === 1) {
            aggregatedData[hospitalId].counter99454 += 1;
        }
        if (entry.cptCode99457?.status === 1) {
            aggregatedData[hospitalId].counter99457 += 1;
        }
        if (entry.cptCode99458First?.status === 1) {
            aggregatedData[hospitalId].counter994581st += 1;
        }
        if (entry.cptCode99458Second?.status === 1) {
            aggregatedData[hospitalId].counter994582nd += 1;
        }
    }

    for (const hospitalId in aggregatedData) {
        const totalCounter = aggregatedData[hospitalId].counter;
        if (totalCounter > 0) {
            aggregatedData[hospitalId].percent99453 = ((aggregatedData[hospitalId].counter99453 / totalCounter) * 100).toFixed(1);
            aggregatedData[hospitalId].percent99454 = ((aggregatedData[hospitalId].counter99454 / totalCounter) * 100).toFixed(1);
            aggregatedData[hospitalId].percent99457 = ((aggregatedData[hospitalId].counter99457 / totalCounter) * 100).toFixed(1);
            aggregatedData[hospitalId].percent994581st = ((aggregatedData[hospitalId].counter994581st / totalCounter) * 100).toFixed(1);
            aggregatedData[hospitalId].percent994582nd = ((aggregatedData[hospitalId].counter994582nd / totalCounter) * 100).toFixed(1);
        }
    }

    return aggregatedData;
}
