// NEW UI
const logoLogin = require('./NewUi/icon_login_logo.svg');

const englishPlayStore = require('./pdfImages/english_play_store.png');
const englishAppStore = require('./pdfImages/english_app_store.png');
const spanishPlayStore = require('./pdfImages/spanish_play_store.png');
const spanishAppStore = require('./pdfImages/spanish_app_store.png');
const androidQrCode = require('./pdfImages/android_qr_code.png');
const iosQrCode = require('./pdfImages/ios_qr_code.png');
const logoDrawer = require('./NewUi/icon_drawer_logo.svg');
const logoWithName = require('./NewUi/logo_with_name.svg');
const addWhite = require('./NewUi/add_white.svg');
const addBlack = require('./NewUi/add_black.svg');
const admins = require('./NewUi/admins.svg');
const airMeter = require('./NewUi/air_meter.svg');
const arrowDown = require('./NewUi/arrow_down.svg');
const arrowLeftLong = require('./NewUi/arrow_left_long.svg');
const arrowLeft = require('./NewUi/arrow_left.svg');
const arrowRight = require('./NewUi/arrow_right.svg');
const arrowUp = require('./NewUi/arrow_up.svg');
const bell = require('./NewUi/bell.svg');
const billWhite = require('./NewUi/bill_white.svg');
const billBlack = require('./NewUi/bill_black.svg');
const brainBlack = require('./NewUi/brain_black.svg');
const brainWhite = require('./NewUi/brain_white.svg');
const building = require('./NewUi/building.svg');
const calendarEvent = require('./NewUi/calendar_event.svg');
const calendar = require('./NewUi/calendar.svg');
const callEnd = require('./NewUi/call_end.svg');
const camera = require('./NewUi/camera.svg');
const chat = require('./NewUi/chat.svg');
const closeBlack = require('./NewUi/close_black.svg');
const closeWhite = require('./NewUi/close_white.svg');
const copy = require('./NewUi/copy.svg');
const cubes = require('./NewUi/cubes.svg');
const dashboardBlack = require('./NewUi/dashboard_black.svg');
const dashboardWhite = require('./NewUi/dashboard_white.svg');
const doctorWhite = require('./NewUi/doctor_white.svg');
const doctorBlack = require('./NewUi/doctor_black.svg');
const drag = require('./NewUi/drag.svg');
const activeDot = require('./NewUi/active_dot.svg');
const edit = require('./NewUi/edit.svg');
const external = require('./NewUi/external.svg');
const elipse = require('./NewUi/elipse.svg');
const elipseBig = require('./NewUi/elipse_big.svg');
const fatScale = require('./NewUi/fat_scale.svg');
const home = require('./NewUi/home.svg');
const info = require('./NewUi/info.svg');
const key = require('./NewUi/key.svg');
const list = require('./NewUi/list.svg');
const mailBlack = require('./NewUi/mail_black.svg');
const mailPurple = require('./NewUi/mail_purple.svg');
const medicalTeams = require('./NewUi/medical_teams_black.svg');
const menu = require('./NewUi/menu.svg');
const meter = require('./NewUi/meter.svg');
const micOff = require('./NewUi/mic_off.svg');
const more = require('./NewUi/more.svg');
const nurse = require('./NewUi/nurse.svg');
const openInNew = require('./NewUi/open_in_new.svg');
const uploadPhoto = require('./NewUi/upload_photo.svg');
const paperclip = require('./NewUi/paperclip.svg');
const phoneBlack = require('./NewUi/phone_black.svg');
const phonePurple = require('./NewUi/phone_purple.svg');
const duplicate = require('./NewUi/duplicateContent.svg');
const search = require('./NewUi/search.svg');
const send = require('./NewUi/send.svg');
const settings = require('./NewUi/settings.svg');
const signOut = require('./NewUi/sign_out.svg');
const sort = require('./NewUi/sort.svg');
const thermometer = require('./NewUi/thermometer.svg');
const trash = require('./NewUi/trash.svg');
const upload = require('./NewUi/upload.svg');
const user = require('./NewUi/user.svg');
const usersBlack = require('./NewUi/users_black.svg');
const usersWhite = require('./NewUi/users_white.svg');
const rectangle = require('./NewUi/rectangle.svg');
const verifier = require('./NewUi/verifier.svg');
const share = require('./NewUi/share.svg');
const warning = require('./NewUi/warning.svg');
// --------------------------
const burgerMenu = require('./icons/burger_menu_color.svg');
const patientsBlack = require('./icons/patients_black.svg');
const patientsWhite = require('./icons/patients_white.svg');
const medicalTeamBlack = require('./icons/medical_team_black.svg');
const medicalTeamWhite = require('./icons/medical_team_white.svg');
const supportBlack = require('./icons/supportBlack.svg');
const supportWhite = require('./icons/supportWhite.svg');
const profileBlack = require('./icons/profile_black.svg');
const settingsBlack = require('./icons/setings_black.svg');
const hospitalBlack = require('./icons_v2/hospital_black.svg');
const hospitalWhite = require('./icons_v2/hospital_white.svg');
const mentalHealthWhite = require('./icons_v2/mentalHealthWhite.svg');
const mentalHealthBlack = require('./icons_v2/mentalHealthBlack.svg');
const mentalHealthWhiteAssign = require('./icons_v2/SAMT_white.svg');
const mentalHealthBlackAssign = require('./icons_v2/SAMT_black.svg');
const wellnessAssessmentPrimary = require('./icons_v2/wellness_assessment_primary.svg');
const wellnessAssessmentBlack = require('./icons_v2/wellness_assessment_black.svg');

const signOutBlack = require('./icons_v2/sign_out_black.svg');
const wellnessMetric = require('./icons_v2/logo_wellnessmetric.svg');
const wellnessMetricNoTitle = require('./icons_v2/wellnessmetric_no_title.svg');
const allergies = require('./pdfImages/allergies-removebg-preview.png');
const calender = require('./pdfImages/calender-removebg-preview.png');
const email = require('./pdfImages/email-removebg-preview.png');
const height = require('./pdfImages/height-removebg-preview.png');
const homemed = require('./pdfImages/home_med-removebg-preview.png');
const location = require('./pdfImages/location-removebg-preview.png');
const perscription = require('./pdfImages/perscription-removebg-preview.png');
const phone1 = require('./pdfImages/phone-removebg-preview.png');
const weight = require('./pdfImages/weight-removebg-preview.png');
const accountOutline1 = require('./icons_v2/account-outline.png');
const verifireBlack1 = require('./icons/verifireDark.svg');
const verifireWhite = require('./icons/verifireWhite.svg');

const seeMoreBlack = require('./icons_v2/see_more_black.svg');
const seeMoreWhite = require('./icons_v2/see_more_white.svg');

const burgerMenuPrimary = require('./icons_v2/burger_menu_color.svg');
const profileSettingsBlack = require('./icons_v2/profile_settings_black.svg');

const abnormalNotification = require('./icons/abnormal_notification_White.svg');
const allNotification = require('./icons/all_notifications_white.svg');
const missedNotificationWhite = require('./icons/missed_notification_white.svg');
const messagesNotificationWhite = require('./icons/messages_notification_white.svg');
const appointmentsWhite = require('./icons/appointments_white.svg');

const abnormalNotificationRed = require('./icons/abnormal_notification_Color.svg');
const missedNotificationColor = require('./icons/missed_notification_color.svg');
const messagesNotificationBlack = require('./icons/messages_notification_black.svg');
const appointmentsBlack = require('./icons/appointments_black.svg');

const pencilImg = require('./icons/edit_white.svg');
const attachWhite = require('./icons/attach_white.svg');
const videocallWhite = require('./icons_v2/videocall_white.svg');
const videocallBlack = require('./icons_v2/videocall_black.svg');

const glucoseMeterWhtie = require('./icons/glucose_meter_whtie.svg');
const cholesterolMeterWhite = require('./icons/cholesterol_meter_White.svg');
const oxymeterWhite = require('./icons/oxymeter_White.svg');
const scaleWhite = require('./icons/scale_white.svg');
const thermometerWhite = require('./icons/thermometer_White.svg');
const bloodPressureWhite = require('./icons/blood_pressure_white.svg');
const sendButtonWhite = require('./icons/send_button_white.svg');
const devicesBlack = require('./icons/devices_black.svg');
const devicesWhite = require('./icons/devices_white.svg');
const monitoringPlanBlack = require('./icons/monitoring_plan_black.svg');
const monitoringPlanPrimary = require('./icons/monitoring_plan_white.svg');
const doctorPrimary = require('./icons/doctor_primary.svg');
const doctorSecondary = require('./icons/doctor_secondary.svg');
const nurseBlack = require('./icons/nurse_black.svg');
const nurseSecondary = require('./icons/nurse_secondary.svg');
const patientBlack = require('./icons/patient_black.svg');
const patientWhite = require('./icons/patient_white.svg');
const patientSecondary = require('./icons/patient_secondary.svg');

const glucoseMeterPrimary = require('./icons/glucose_meter_primary.svg');
const cholesterolMeterPrimary = require('./icons/cholesterol_meter_primary.svg');
const oxymeterPrimary = require('./icons/oxymeter_primary.svg');
const scalePrimary = require('./icons/scale_primary.svg');
const thermometerPrimary = require('./icons/thermometer_primary.svg');
const bloodPressurePrimary = require('./icons/blood_pressure_primary.svg');

const bloodPressureBlack = require('./icons/blood_pressure_black.svg');
const cholesterolMeterBlack = require('./icons/cholesterol_meter_black.svg');
const glucoseMeterBlack = require('./icons/glucose_meter_black.svg');
const oxymeterBlack = require('./icons/oxymeter_black.svg');
const scaleBlack = require('./icons/scale_black.svg');
const thermometerBlack = require('./icons/thermometer_black.svg');

const organizationsBlack = require('./icons_v2/organizations_black.svg');
const organizationsWhite = require('./icons_v2/organizations_white.svg');

const thermometerGrey = require('./icons/thermometer_grey.svg');
const bloodPressureGrey = require('./icons/blood_pressure_grey.svg');
const glucoseMeterGrey = require('./icons/glucose_meter_grey.svg');
const oxymeterGrey = require('./icons/oxymeter_grey.svg');
const scaleGrey = require('./icons/scale_grey.svg');
const cholesterolMeterGrey = require('./icons/cholesterol_meter_grey.svg');
const medicalNote = require('./icons/medical_note.svg');
const reminder = require('./icons_v2/reminder.svg');
const dashboardwhite = require('./icons_v2/dashboard_white.svg');
const medicalStaffTransfer = require('./icons_v2/medical_transfer.svg');
const letterM = require('./icons_v2/layer1.svg');
const letterA = require('./icons_v2/letterA.svg');
const activity = require('./NewUi/activity_white.svg');
const ecp = require('./pdfImages/ecp.jpeg');
const dataMapping = require('./icons_v2/data_mapping.svg');
const convertArrows = require('./icons_v2/convert-arrows-icon.svg');
const logs = require('./NewUi/logs.svg');
export {
	logs,
	convertArrows,
	dataMapping,
	ecp,
	activity,
	duplicate,
	share,
	medicalNote,
	medicalStaffTransfer,
	uploadPhoto,
	rectangle,
	activeDot,
	elipse,
	elipseBig,
	addWhite,
	addBlack,
	admins,
	airMeter,
	arrowDown,
	arrowLeftLong,
	arrowLeft,
	arrowRight,
	arrowUp,
	bell,
	billWhite,
	billBlack,
	brainWhite,
	brainBlack,
	building,
	calendarEvent,
	calendar,
	callEnd,
	camera,
	chat,
	closeBlack,
	closeWhite,
	logoLogin,
	logoDrawer,
	copy,
	cubes,
	dashboardWhite,
	dashboardBlack,
	doctorWhite,
	doctorBlack,
	drag,
	edit,
	external,
	fatScale,
	home,
	info,
	key,
	list,
	mailPurple,
	mailBlack,
	medicalTeams,
	menu,
	wellnessAssessmentPrimary,
	wellnessAssessmentBlack,
	meter,
	micOff,
	more,
	nurse,
	openInNew,
	paperclip,
	phoneBlack,
	phonePurple,
	phone1,
	search,
	send,
	settings,
	signOut,
	sort,
	thermometer,
	trash,
	upload,
	user,
	usersBlack,
	usersWhite,
	verifier,
	warning,
	logoWithName,
	mentalHealthBlackAssign,
	letterM,
	letterA,
	mentalHealthWhiteAssign,
	mentalHealthWhite,
	mentalHealthBlack,
	verifireBlack1,
	verifireWhite,
	supportWhite,
	androidQrCode,
	iosQrCode,
	supportBlack,
	reminder,
	allergies,
	calender,
	homemed,
	email,
	height,
	location,
	weight,
	dashboardwhite,
	perscription,
	devicesWhite,
	appointmentsBlack,
	accountOutline1,
	bloodPressureGrey,
	messagesNotificationBlack,
	missedNotificationColor,
	organizationsBlack,
	patientWhite,
	englishPlayStore,
	englishAppStore,
	spanishPlayStore,
	spanishAppStore,
	organizationsWhite,
	seeMoreWhite,
	seeMoreBlack, wellnessMetric, burgerMenuPrimary, signOutBlack, hospitalBlack, hospitalWhite, profileSettingsBlack, medicalTeamWhite, patientsWhite, patientSecondary, patientBlack, nurseSecondary, nurseBlack, doctorSecondary, doctorPrimary, thermometerGrey, glucoseMeterGrey, oxymeterGrey, scaleGrey, cholesterolMeterGrey, bloodPressureBlack, cholesterolMeterBlack, glucoseMeterBlack, oxymeterBlack, scaleBlack, thermometerBlack, thermometerPrimary, scalePrimary, glucoseMeterPrimary, cholesterolMeterPrimary, bloodPressurePrimary, oxymeterPrimary, monitoringPlanBlack, monitoringPlanPrimary, devicesBlack, sendButtonWhite, glucoseMeterWhtie, cholesterolMeterWhite, oxymeterWhite, scaleWhite, thermometerWhite, bloodPressureWhite, burgerMenu, medicalTeamBlack, patientsBlack, settingsBlack, profileBlack, abnormalNotificationRed, abnormalNotification, allNotification, missedNotificationWhite, messagesNotificationWhite, appointmentsWhite, pencilImg, attachWhite, videocallWhite, videocallBlack, wellnessMetricNoTitle,
};
